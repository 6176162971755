const axios = window.axios
const url = '/api/v2/leisure/types/'
const urlImages = '/api/v2/leisure/type-images/'
const urlMainImage = '/api/v2/leisure/type-images/main/'


export const LeisureApiUrls = {
    // тип активного отдыха
    async getLeisureTypes(params) {
        return (await axios.get(url, { params: params })).data;
    },
    async createLeisureType(body) {
        return (await axios.post(url, body)).data;
    },
    async getLeisureType(id) {
        return (await axios.get(`${url}${id}/`)).data;
    },
    async setLeisureType(id, body) {
        return (await axios.patch(`${url}${id}/`, body)).data;
    },
    async deleteLeisureType(id) {
        return (await axios.delete(`${url}${id}/`)).data;
    },

    // картинки активного отдыха
    async createLeisureImage(formdata) {
        return (await axios.post(urlImages, formdata)).data;
    },
    async setLeisureImage(id, formdata) {
        return (await axios.patch(`${urlImages}${id}/`, formdata)).data;
    },
    async deleteLeisureImage(id) {
        return (await axios.delete(`${urlImages}${id}/`)).data;
    },

    // главная картинка 
    async setLeisureMainImage(id, formdata) {
        return (await axios.patch(`${urlMainImage}${id}/`, formdata)).data;
    },



};
  